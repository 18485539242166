import {
	CLEAR_ALL_FILTERS,
	CLEAR_CONTAINER_FILTER,
	CLEAR_CONTRACTS_FILTER,
	CLEAR_ETA_FILTER,
	CLEAR_ETS_FILTER,
	CLEAR_LOAD_FILTER,
	CLEAR_PO_FILTER,
	CLEAR_PRODUCT_FILTER,
	CLEAR_TRANSPORT_FILTER,
	//container
	CONTAINER_FILTER_REQUEST,
	CONTAINER_FILTER_REQUEST_ERROR,
	CONTAINER_FILTER_REQUEST_SUCCESS,
	//contract
	CONTRACTS_FILTER_REQUEST,
	CONTRACTS_FILTER_REQUEST_ERROR,
	CONTRACTS_FILTER_REQUEST_SUCCESS,
	//load
	LOAD_FILTER_REQUEST,
	LOAD_FILTER_REQUEST_ERROR,
	LOAD_FILTER_REQUEST_SUCCESS,
	//invoice
	LOAD_INVOICE_FILTER_REQUEST,
	LOAD_INVOICE_FILTER_REQUEST_SUCCESS,
	LOAD_INVOICE_FILTER_REQUEST_ERROR,
	SAVE_LOAD_INVOICE_FILTER,
	//po
	PO_FILTER_REQUEST,
	PO_FILTER_REQUEST_ERROR,
	PO_FILTER_REQUEST_SUCCESS,
	//product
	PRODUCT_FILTER_REQUEST,
	PRODUCT_FILTER_REQUEST_ERROR,
	PRODUCT_FILTER_REQUEST_SUCCESS,
	SAVE_CONTAINER_FILTER,
	SAVE_CONTRACTS_FILTER,
	//eta
	SAVE_ETA_FILTER,
	//ets
	SAVE_ETS_FILTER,
	//last query string
	SAVE_LAST_QUERY_STRING,
	SAVE_LOAD_FILTER,
	SAVE_PO_FILTER,
	SAVE_PRODUCT_FILTER,
	SAVE_TRANSPORT_FILTER,
	//transport
	TRANSPORT_FILTER_REQUEST,
	TRANSPORT_FILTER_REQUEST_ERROR,
	TRANSPORT_FILTER_REQUEST_SUCCESS,
} from './actions';

import api from '@/utils/api';
import { addUniqueItemsToList } from '@/utils/filtersHelper';

const { http } = api.getInstance();

const defaultState = () => ({
	//contract
	contractsFilter: [],
	contractsFilterMeta: [],
	contractsFilterCache: {
		value: [],
	},
	isLoadingContractsFilter: '',
	//po
	poFilter: [],
	poFilterMeta: [],
	poFilterCache: {
		value: [],
	},
	//load
	loadFilter: [],
	loadFilterMeta: [],
	loadFilterCache: {
		value: [],
	},
	isLoadingLoadFilter: '',
	//invoice
	invoiceFilter: [],
	invoiceFilterMeta: {},
	invoiceFilterCache: {
		value: [],
	},
	isLoading: false,
	//transport
	transportFilter: [],
	transportFilterMeta: [],
	transportFilterCache: {
		value: [],
	},
	isLoadingTransportFilter: '',
	//container
	containerFilter: [],
	containerFilterMeta: [],
	containerFilterCache: {
		value: [],
	},
	isLoadingContainerFilter: '',
	//product
	productFilter: [],
	productFilterMeta: [],
	productFilterCache: {
		value: [],
	},
	isLoadingProductFilter: '',
	//ets
	etsFilter: [],
	etsFilterMeta: [],
	etsFilterCache: {
		value: [],
	},
	isLoadingEtsFilter: '',
	//eta
	etaFilter: [],
	etaFilterMeta: [],
	etaFilterCache: {
		value: [],
	},
	isLoadingEtaFilter: '',
	//lastQueryString
	lastQueryString: '',
});

const state = defaultState();

const getters = {
	//contract
	contractsFilter: (state) => state.contractsFilter,
	contractsFilterMeta: (state) => state.contractsFilterMeta,
	contractsFilterCache: (state) => state.contractsFilterCache,
	isLoadingContractsFilter: (state) => state.isLoadingContractsFilter === 'loading',
	//po
	poFilter: (state) => state.poFilter,
	poFilterMeta: (state) => state.poFilterMeta,
	poFilterCache: (state) => state.poFilterCache,
	isLoadingPoFilter: (state) => state.isLoadingPoFilter === 'loading',
	//load
	loadFilter: (state) => state.loadFilter,
	loadFilterMeta: (state) => state.loadFilterMeta,
	loadFilterCache: (state) => state.loadFilterCache,
	isLoadingLoadFilter: (state) => state.isLoadingLoadFilter === 'loading',
	// invoice
	invoiceFilter: (state) => state.invoiceFilter,
	invoiceFilterMeta: (state) => state.invoiceFilterMeta,
	invoiceFilterCache: (state) => state.invoiceFilterCache,
	isLoadingInvoiceFilter: (state) => state.isLoading === true,
	//transport
	transportFilter: (state) => state.transportFilter,
	transportFilterMeta: (state) => state.transportFilterMeta,
	transportFilterCache: (state) => state.transportFilterCache,
	isLoadingTransportFilter: (state) => state.isLoadingTransportFilter === 'loading',
	//container
	containerFilter: (state) => state.containerFilter,
	containerFilterMeta: (state) => state.containerFilterMeta,
	containerFilterCache: (state) => state.containerFilterCache,
	isLoadingContainerFilter: (state) => state.isLoadingContainerFilter === 'loading',
	//product
	productFilter: (state) => state.productFilter,
	productFilterMeta: (state) => state.productFilterMeta,
	productFilterCache: (state) => state.productFilterCache,
	isLoadingProductFilter: (state) => state.isLoadingProductFilter === 'loading',
	//ets
	etsFilter: (state) => state.etsFilter,
	etsFilterMeta: (state) => state.etsFilterMeta,
	etsFilterCache: (state) => state.etsFilterCache,
	isLoadingEtsFilter: (state) => state.isLoadingEtsFilter === 'loading',
	//eta
	etaFilter: (state) => state.etaFilter,
	etaFilterMeta: (state) => state.etaFilterMeta,
	etaFilterCache: (state) => state.etaFilterCache,
	isLoadingEtaFilter: (state) => state.isLoadingEtaFilter === 'loading',
	//lastQueryString
	lastQueryString: (state) => state.lastQueryString,
};

const mutations = {
	[CLEAR_ALL_FILTERS]: (state) => {
		state.contractsFilterCache = {
			value: [],
		};
		state.poFilterCache = {
			value: [],
		};
		state.loadFilterCache = {
			value: [],
		};
		state.transportFilterCache = {
			value: [],
		};
		state.containerFilterCache = {
			value: [],
		};
		state.productFilterCache = {
			value: [],
		};
		state.etsFilterCache = {
			value: [],
		};
		state.etaFilterCache = {
			value: [],
		};
		state.lastQueryString = '';
	},
	//CONTRACT FILTER
	[CONTRACTS_FILTER_REQUEST]: (state) => {
		state.isLoadingContractsFilter = 'loading';
	},
	[CONTRACTS_FILTER_REQUEST_SUCCESS]: (state, data) => {
		state.contractsFilterMeta = data.meta;

		if (data.meta.currentPage === 1) {
			state.contractsFilter = [];
		}

		if (data.items && data.items.length) {
			const formattedItems = data.items.map((item) => ({
				id: item.contractNumberSequence,
				name: item.contractNumberSequence,
			}));
			addUniqueItemsToList(state, formattedItems, 'contractsFilter', 'name');
		}

		state.isLoadingContractsFilter = 'success';
	},
	[CONTRACTS_FILTER_REQUEST_ERROR]: (state) => {
		state.contractsFilter = [];
		state.contractsFilterMeta = [];
		state.isLoadingContractsFilter = 'error';
	},
	[SAVE_CONTRACTS_FILTER]: (state, data) => {
		state.contractsFilterCache = data;
	},
	[CLEAR_CONTRACTS_FILTER]: (state) => {
		state.contractsFilter = [];
	},
	//PO FILTER
	[PO_FILTER_REQUEST]: (state) => {
		state.isLoadingPoFilter = 'loading';
	},
	[PO_FILTER_REQUEST_SUCCESS]: (state, data) => {
		state.poFilterMeta = data.meta;

		if (data.meta.currentPage === 1) {
			state.poFilter = [];
		}

		if (data.items && data.items.length) {
			const formattedItems = data.items.map((item) => ({
				id: item.purchaseOrder,
				name: item.purchaseOrder,
			}));
			addUniqueItemsToList(state, formattedItems, 'poFilter', 'name');
		}

		state.isLoadingPoFilter = 'success';
	},
	[PO_FILTER_REQUEST_ERROR]: (state) => {
		state.poFilter = [];
		state.poFilterMeta = [];
		state.isLoadingPoFilter = 'error';
	},
	[SAVE_PO_FILTER]: (state, data) => {
		state.poFilterCache = data;
	},
	[CLEAR_PO_FILTER]: (state) => {
		state.poFilter = [];
	},
	//LOAD FILTER
	[LOAD_FILTER_REQUEST]: (state) => {
		state.isLoadingLoadFilter = 'loading';
	},
	[LOAD_FILTER_REQUEST_SUCCESS]: (state, data) => {
		state.loadFilterMeta = data.meta;

		if (data.meta.currentPage === 1) {
			state.loadFilter = [];
		}

		if (data.items && data.items.length) {
			const formattedItems = data.items.map((item) => ({
				id: item.loadNumber,
				name: item.loadNumber,
			}));

			addUniqueItemsToList(state, formattedItems, 'loadFilter', 'name');
		}

		state.isLoadingLoadFilter = 'success';
	},
	[LOAD_FILTER_REQUEST_ERROR]: (state) => {
		state.loadFilter = [];
		state.loadFilterMeta = [];
		state.isLoadingLoadFilter = 'error';
	},
	[SAVE_LOAD_FILTER]: (state, data) => {
		state.loadFilterCache = data;
	},
	[CLEAR_LOAD_FILTER]: (state) => {
		state.loadFilter = [];
	},
	[LOAD_INVOICE_FILTER_REQUEST]: (state) => {
		state.isLoading = true;
	},
	[LOAD_INVOICE_FILTER_REQUEST_SUCCESS]: (state, data) => {
		if (data.items?.length) state.invoiceFilter = data.items.map((item) => item.invoiceNumber);
		state.isLoading = false;
		state.invoiceFilterMeta = data.meta;
	},
	[LOAD_INVOICE_FILTER_REQUEST_ERROR]: (state) => {
		state.isLoading = false;
		state.invoiceFilter = [];
	},
	[SAVE_LOAD_INVOICE_FILTER]: (state, data) => {
		state.invoiceFilterCache = data;
	},
	//TRANSPORT FILTER
	[TRANSPORT_FILTER_REQUEST]: (state) => {
		state.transportFilterMeta = [];
		state.isLoadingTransportFilter = 'loading';
	},
	[TRANSPORT_FILTER_REQUEST_SUCCESS]: (state, data) => {
		state.transportFilterMeta = data.meta;

		if (data.meta.currentPage === 1) {
			state.transportFilter = [];
		}

		if (data.items && data.items.length) {
			const formattedItems = data.items.map((item) => ({ id: item.vessel, name: item.vessel }));
			addUniqueItemsToList(state, formattedItems, 'transportFilter', 'name');
		}

		state.isLoadingTransportFilter = 'success';
	},
	[TRANSPORT_FILTER_REQUEST_ERROR]: (state) => {
		state.transportFilter = [];
		state.transportFilterMeta = [];
		state.isLoadingTransportFilter = 'error';
	},
	[SAVE_TRANSPORT_FILTER]: (state, data) => {
		state.transportFilterCache = data;
	},
	[CLEAR_TRANSPORT_FILTER]: (state) => {
		state.transportFilter = [];
	},
	//CONTAINER FILTER
	[CONTAINER_FILTER_REQUEST]: (state) => {
		state.containerFilterMeta = [];
		state.isLoadingContainerFilter = 'loading';
	},
	[CONTAINER_FILTER_REQUEST_SUCCESS]: (state, data) => {
		state.containerFilterMeta = data.meta;

		if (data.meta.currentPage === 1) {
			state.containerFilter = [];
		}

		if (data.items && data.items.length) {
			const formattedItems = data.items.map((item) => ({
				id: item.container,
				name: item.container,
			}));
			addUniqueItemsToList(state, formattedItems, 'containerFilter', 'name');
		}

		state.isLoadingContainerFilter = 'success';
	},
	[CONTAINER_FILTER_REQUEST_ERROR]: (state) => {
		state.containerFilter = [];
		state.containerFilterMeta = [];
		state.isLoadingContainerFilter = 'error';
	},
	[SAVE_CONTAINER_FILTER]: (state, data) => {
		state.containerFilterCache = data;
	},
	[CLEAR_CONTAINER_FILTER]: (state) => {
		state.containerFilter = [];
	},
	//PRODUCT FILTER
	[PRODUCT_FILTER_REQUEST]: (state) => {
		state.productFilterMeta = [];
		state.isLoadingProductFilter = 'loading';
	},
	[PRODUCT_FILTER_REQUEST_SUCCESS]: (state, data) => {
		state.productFilterMeta = data.meta;

		if (data.meta.currentPage === 1) {
			state.productFilter = [];
		}

		if (data.items && data.items.length) {
			const formattedItems = data.items.map((item) => ({ id: item.product, name: item.product }));
			addUniqueItemsToList(state, formattedItems, 'productFilter', 'name');
		}

		state.isLoadingProductFilter = 'success';
	},
	[PRODUCT_FILTER_REQUEST_ERROR]: (state) => {
		state.productFilter = [];
		state.productFilterMeta = [];
		state.isLoadingProductFilter = 'error';
	},
	[SAVE_PRODUCT_FILTER]: (state, data) => {
		state.productFilterCache = data;
	},
	[CLEAR_PRODUCT_FILTER]: (state) => {
		state.productFilter = [];
	},
	//ETS FILTER
	[SAVE_ETS_FILTER]: (state, data) => {
		state.etsFilterCache = data;
	},
	[CLEAR_ETS_FILTER]: (state) => {
		state.etsFilter = [];
	},
	//ETA FILTER
	[SAVE_ETA_FILTER]: (state, data) => {
		state.etaFilterCache = data;
	},
	[CLEAR_ETA_FILTER]: (state) => {
		state.etaFilter = [];
	},
	//LAST QUERY STRING
	[SAVE_LAST_QUERY_STRING]: (state, data) => {
		state.lastQueryString = data;
	},
};

const actions = {
	[CLEAR_ALL_FILTERS]: ({ commit }) => {
		commit(CLEAR_ALL_FILTERS);
	},
	//CONTRACT FILTER
	[CONTRACTS_FILTER_REQUEST]: ({ commit }, { vm, params, skip, lastQuery }) => {
		return new Promise((resolve) => {
			commit(CONTRACTS_FILTER_REQUEST);

			const headerCompanyIdStored = vm.$store.state.filters.headerCompanyFilterCache.value.id;

			let url = `/loads/contracts?companyId=${headerCompanyIdStored}&page=1&limit=20`;

			if (skip) {
				let newQuery = new URLSearchParams(params);
				newQuery.delete('contractNumberSequence');
				url = `/loads/contracts?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			} else if (!skip && params) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('contractNumberSequence');
				url = `/loads/contracts?companyId=${headerCompanyIdStored}&${params}&${newQuery.toString()}`;
			} else if (!skip && !params && lastQuery) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('contractNumberSequence');
				url = `/loads/contracts?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(CONTRACTS_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(CONTRACTS_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[SAVE_CONTRACTS_FILTER]: ({ commit }, data) => {
		commit(SAVE_CONTRACTS_FILTER, data);
	},
	//PO FILTER
	[PO_FILTER_REQUEST]: ({ commit }, { vm, params, skip, lastQuery }) => {
		return new Promise((resolve) => {
			commit(PO_FILTER_REQUEST);

			const headerCompanyIdStored = vm.$store.state.filters.headerCompanyFilterCache.value.id;

			let url = `/loads/purchaseOrders?companyId=${headerCompanyIdStored}&page=1&limit=20`;

			if (skip) {
				let newQuery = new URLSearchParams(params);
				newQuery.delete('purchaseOrder');
				url = `/loads/purchaseOrders?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			} else if (!skip && params) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('purchaseOrder');
				url = `/loads/purchaseOrders?companyId=${headerCompanyIdStored}&${params}&${newQuery.toString()}`;
			} else if (!skip && !params && lastQuery) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('purchaseOrder');
				url = `/loads/purchaseOrders?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(PO_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(PO_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[SAVE_PO_FILTER]: ({ commit }, data) => {
		commit(SAVE_PO_FILTER, data);
	},
	//LOAD FILTER
	[LOAD_FILTER_REQUEST]: ({ commit }, { vm, params, skip, lastQuery }) => {
		return new Promise((resolve) => {
			commit(LOAD_FILTER_REQUEST);

			const headerCompanyIdStored = vm.$store.state.filters.headerCompanyFilterCache.value.id;

			let url = `/loads/loadNumbers?companyId=${headerCompanyIdStored}&page=1&limit=20`;

			if (skip) {
				let newQuery = new URLSearchParams(params);
				newQuery.delete('loadNumber');
				url = `/loads/loadNumbers?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			} else if (!skip && params) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('loadNumber');
				url = `/loads/loadNumbers?companyId=${headerCompanyIdStored}&${params}&${newQuery.toString()}`;
			} else if (!skip && !params && lastQuery) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('loadNumber');
				url = `/loads/loadNumbers?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(LOAD_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(LOAD_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[SAVE_LOAD_FILTER]: ({ commit }, data) => {
		commit(SAVE_LOAD_FILTER, data);
	},

	[LOAD_INVOICE_FILTER_REQUEST]: ({ commit }, { vm, params, skip, lastQuery }) => {
		return new Promise((resolve) => {
			commit(LOAD_INVOICE_FILTER_REQUEST);

			const headerCompanyIdStored = vm.$store.state.filters.headerCompanyFilterCache.value.id;

			let url = `/loads/invoices?companyId=${headerCompanyIdStored}&page=1&limit=20`;

			if (skip) {
				let newQuery = new URLSearchParams(params);
				newQuery.delete('invoice');
				url = `/loads/invoices?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			} else if (!skip && params) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `/loads/invoices?companyId=${headerCompanyIdStored}&${params}&${newQuery.toString()}`;
			} else if (!skip && !params && lastQuery) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `/loads/invoices?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(LOAD_INVOICE_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(LOAD_INVOICE_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},

	[SAVE_LOAD_INVOICE_FILTER]: ({ commit }, data) => {
		commit(SAVE_LOAD_INVOICE_FILTER, data);
	},
	//TRANSPORT FILTER
	[TRANSPORT_FILTER_REQUEST]: ({ commit }, { vm, params, skip, lastQuery }) => {
		return new Promise((resolve) => {
			commit(TRANSPORT_FILTER_REQUEST);

			const headerCompanyIdStored = vm.$store.state.filters.headerCompanyFilterCache.value.id;

			let url = `/loads/vessels?companyId=${headerCompanyIdStored}&page=1&limit=20`;

			if (skip) {
				let newQuery = new URLSearchParams(params);
				newQuery.delete('vessel');
				url = `/loads/vessels?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			} else if (!skip && params) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('vessel');
				url = `/loads/vessels?companyId=${headerCompanyIdStored}&${params}&${newQuery.toString()}`;
			} else if (!skip && !params && lastQuery) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('vessel');
				url = `/loads/vessels?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(TRANSPORT_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(TRANSPORT_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[SAVE_TRANSPORT_FILTER]: ({ commit }, data) => {
		commit(SAVE_TRANSPORT_FILTER, data);
	},
	//CONTAINER FILTER
	[CONTAINER_FILTER_REQUEST]: ({ commit }, { vm, params, skip, lastQuery }) => {
		return new Promise((resolve) => {
			commit(CONTAINER_FILTER_REQUEST);

			const headerCompanyIdStored = vm.$store.state.filters.headerCompanyFilterCache.value.id;

			let url = `/loads/containers?companyId=${headerCompanyIdStored}&page=1&limit=20`;

			if (skip) {
				let newQuery = new URLSearchParams(params);
				newQuery.delete('container');
				url = `/loads/containers?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			} else if (!skip && params) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('container');
				url = `/loads/containers?companyId=${headerCompanyIdStored}&${params}&${newQuery.toString()}`;
			} else if (!skip && !params && lastQuery) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('container');
				url = `/loads/containers?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(CONTAINER_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(CONTAINER_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[SAVE_CONTAINER_FILTER]: ({ commit }, data) => {
		commit(SAVE_CONTAINER_FILTER, data);
	},
	//PRODUCT FILTER
	[PRODUCT_FILTER_REQUEST]: ({ commit }, { vm, params, skip, lastQuery }) => {
		return new Promise((resolve) => {
			commit(PRODUCT_FILTER_REQUEST);

			const headerCompanyIdStored = vm.$store.state.filters.headerCompanyFilterCache.value.id;

			let url = `/loads/products?companyId=${headerCompanyIdStored}&page=1&limit=20`;

			if (skip) {
				let newQuery = new URLSearchParams(params);
				newQuery.delete('product');
				url = `/loads/products?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			} else if (!skip && params) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('product');
				url = `/loads/products?companyId=${headerCompanyIdStored}&${params}&${newQuery.toString()}`;
			} else if (!skip && !params && lastQuery) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('product');
				url = `/loads/products?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(PRODUCT_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(PRODUCT_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[SAVE_PRODUCT_FILTER]: ({ commit }, data) => {
		commit(SAVE_PRODUCT_FILTER, data);
	},
	//ETS FILTER
	[SAVE_ETS_FILTER]: ({ commit }, data) => {
		commit(SAVE_ETS_FILTER, data);
	},
	//ETA FILTER
	[SAVE_ETA_FILTER]: ({ commit }, data) => {
		commit(SAVE_ETA_FILTER, data);
	},
	//LAST QUERY STRING
	[SAVE_LAST_QUERY_STRING]: ({ commit }, data) => {
		commit(SAVE_LAST_QUERY_STRING, data);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
