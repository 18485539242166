export default {
	placeholder: {
		search: 'Buscar',
	},
	header: {
		contractNumber: 'Contrato',
		contractDate: 'Data do Contrato',
		competenceMonth: 'Mês de Competência',
		releaseDate: 'Data de Lançamento',
		company: 'Empresa',
		status: 'Etapa',
		totalVolume: 'Volume Total',
		emailNotification: 'Notificação',
	},
	detail: {
		actualDateOfBoarding: 'Data atual de embarque',
		awbCourier: 'Postagem AWB',
		balance: 'Saldo',
		billLanding: 'Conhecimento Embarque',
		billLandingDate: 'Data Conhecimento Embarque',
		blDate: 'Data Bl',
		blDateId: 'Id Data Bl',
		bookingNumber: 'Número da Reserva',
		boardingType: 'Tipo de Embarque',
		borderCrossingDate: 'Data de travessia de fronteira',
		borderPost: 'Posto da Fronteira',
		borderPostsCode: 'Código do Posto da Fronteira',
		branch: 'Filial',
		brand: 'Marca',
		brandCode: 'Código da Marca',
		business: 'Negócio',
		closedContractSequence: 'Número de Contrato Fechado',
		cneCode: 'Código CNE',
		container: 'Container',
		contractSequence: 'Número do Contrato',
		country: 'País',
		courierDate: 'Data de Postagem',
		courierCompany: 'Empresa de Postagem',
		csiIssuanceDate: 'Data de Liberação do Caminhão (MODAL TERRESTRE)',
		destination: 'Destino',
		destinationPort: 'Portão de Destino',
		destinationPort4: 'Portão de Destino 4',
		dsInternationalOfc: 'DS Internacional',
		dueSituationCode: 'Código de Vencimento',
		endShipDate: 'Data Final de Carga',
		etaPodDate: 'Data ETA Pod ',
		etdPolDate: 'Data Pol ETD',
		etdRealDate: 'Data Real ETD',
		etsRfsDate: 'Data ETS RFS ',
		etsRfsId: 'ETS RFS id',
		expectedEtdDate: 'ETS POL',
		expectedEtbDate: 'ATS POL',
		expectedEtsDate: 'Data Ets Esperada',
		gateInDate: 'Gate In Date',
		idBlock: 'Id de Bloqueio',
		idStatus: 'Id do Status',
		incoterm: 'Incoterm',
		initials: 'Nome do Produto',
		invoiceNumber: 'Número da Fatura',
		invoicedDate: 'Data da Fatura',
		invoiceIssuanceDate: 'Data da Nota Fiscal (MODAL TERRESTRE)',
		loadNumber: 'Número da Carga',
		loadPlace: 'Local da Carga',
		loadCode: 'Código da Carga',
		loadingDate: 'Data de Carregamento (MODAL TERRESTRE)',
		localization: 'Localização',
		market: 'Mercado',
		groupingDescription: 'Agrupamento',
		maxGroupingQuantity: 'Quantidade de Agrupamento Max',
		minGroupingQuantity: 'Quantidade de Agrupamento Min',
		netWeight: 'Peso Líquido',
		origin: 'Origem',
		paymentDeadline: 'Limite para Pagamento',
		paymentDeadlineCode: 'Código do Limite para Pagamento',
		paymentMethod: 'Método de Pagamento',
		paymentMode: 'Modo de Pagamento',
		permissionImt: 'Permissão Imt',
		permissionImtDate: 'Data de Permissão Imt',
		planningCode: 'Código de Planejamento',
		plannerLoadingDate: 'Data Planejada de Carga',
		pod: 'POD',
		pol: 'POL',
		priceType: 'Tipo de Preço',
		productionMonth: 'Mês de Produção',
		productionDate: 'Production Date',
		productName: 'Nome do Produto',
		provider: 'Fornecedor',
		purchaseOrder: 'Código da Compra',
		purchaseOrderClient: 'Código da Compra Cliente',
		realAtdDate: 'Data Atd Real',
		realAtbDate: 'ETA POD',
		realArrivalDate: 'Data Real da Chegada',
		regional: 'Regional',
		releaseSequenceDate: 'Data de Lançamento Sequencia',
		restritionCode: 'Código da Restrição',
		remarks: 'Observações',
		sentDraftDate: 'Data de Envio do Rascunho',
		scheduledDate: 'Data Agendada',
		shippingCompany: 'Empresa de Embarque',
		shippingLine: 'Linha de Embarque',
		sif: 'Sif Fil',
		sku: 'SKU',
		startShipDate: 'Data Inicial de Carga',
		statusClosure: 'Fechamento de Status',
		statusPrePaid: 'Status Pré Pagamento',
		status: 'Status da Sequência',
		stockLocation: 'Localização de Estoque',
		totalGrossWeight: 'Peso Bruto Total',
		totalKg: 'Kg Total',
		totalVolume: 'Volume Total',
		traderName: 'Nome do Comerciante',
		unitPrice: 'Preço Unitário',
		vehiclePlate: 'Placa do veículo',
		vessel: 'Navio',
		weeksToShip: 'Semanas para Carga',
		stage: 'Status',
	},
};
