<template>
	<div id="contract-management" class="contract-management">
		<Content>
			<template v-slot:header>
				<MobileFilter
					:buttonLabel="$t('buttons.edit_column')"
					@handleModal="handleModal()"
					v-if="!isWeb"
				>
					<template v-slot:inputs-list>
						<FilterList
							ref="filterManagement"
							@setPageOne="setPageOne"
							@refreshContracts="refreshContracts"
							@refreshFilters="refreshFilters"
							@updateFilters="updateFilters"
							:isWeb="isWeb"
						/>
					</template>
				</MobileFilter>
				<WebFilter v-else>
					<template v-slot:filter-actions>
						<div class="row filter-actions">
							<button class="btn-filter" @click="handleModal()">
								{{ $t('buttons.edit_column') }}
							</button>
						</div>
					</template>
					<template v-slot:inputs-list>
						<FilterList
							ref="filterManagement"
							@setPageOne="setPageOne"
							@refreshContracts="refreshContracts"
							@refreshFilters="refreshFilters"
							@updateFilters="updateFilters"
							:isWeb="isWeb"
						/>
					</template>
				</WebFilter>
			</template>
			<template v-slot:body>
				<div class="sorting-row" v-if="userPreferences && userPreferences.header">
					<div
						class="sorting-tag"
						v-for="item in [
							...userPreferences.header.filter((item) => item.active),
							{ name: 'emailNotification', active: true },
						]"
						:key="item.name"
					>
						<span>
							{{ $t(`modules.contract_management.table.header.${item.name}`) }}
						</span>
						<div class="header-icon" @click="toggleSort(item.name)">
							<FilterTable />
						</div>
					</div>
				</div>

				<div v-if="!isLoadingPrefereces && !isLoadingContracts">
					<ContractRow
						v-for="contract in contractsList"
						:ref="`contract-row-${contract.id}`"
						:key="contract.id"
						:data="contract"
						:isWeb="isWeb"
						:page="page"
						:contractId="contract.id"
						@closeAllTables="closeAllTables"
					/>
				</div>
				<div class="loader" v-else>
					<SpinnerIcon />
				</div>
				<div
					class="no__data-variant"
					v-if="contractsList.length == 0 && !isLoadingPrefereces && !isLoadingContracts"
				>
					{{ $t('errors.empty_data') }}
				</div>
			</template>
			<template v-slot:footer>
				<div class="footer">
					<Pagination
						class="contract-pagination"
						ref="pagination"
						v-if="contractsList.length != 0 && !isLoadingPrefereces && !isLoadingContracts"
						:totalPages="pagination.totalPages"
						:currentFromReq="pagination.currentPage"
						:currentFromStore="pageFromStore"
						:maxPageView="3"
						@tableRequest="changePage"
					/>
					<button
						class="download-btn"
						:class="isLoadingDownload ? 'disabled' : ''"
						id="download-btn"
						type="button"
						@click="downloadContracts"
						v-if="contractsList.length != 0 && !isLoadingPrefereces && !isLoadingContracts"
					>
						Download
					</button>
				</div>
			</template>
		</Content>
		<ColumnsModal
			v-if="showEditHeaderModal"
			@closeModal="handleModal()"
			@refreshContracts="refreshContracts()"
			:selectedTable="selectedTable"
			:isWeb="isWeb"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	CONTRACT_MANAGEMENT_REQUEST,
	CONTRACT_PREFERENCES_REQUEST,
	CONTRACT_DOWNLOAD_REQUEST,
} from '@/store/modules/contractManagement/actions';
import {
	CONTRACTS_FILTER_REQUEST,
	COMPANY_FILTER_REQUEST,
	MARKET_FILTER_REQUEST,
	PO_FILTER_REQUEST,
} from '@/store/common/filters/actions';
import Content from '@/components/shared/Content/Content.vue';
import MobileFilter from '@/components/shared/MobileFilter/MobileFilter.vue';
import WebFilter from '@/components/shared/WebFilter/WebFilter.vue';
import ColumnsModal from '@/components/modules/ContractManagement/ColumnsModal/ColumnsModal.vue';
import FilterList from '@/components/modules/ContractManagement/Filter/FilterList.vue';
import ContractRow from '@/components/modules/ContractManagement/ContractRow/ContractRow.vue';
import SpinnerIcon from '@/components/shared/Icons/SpinnerIcon';
import Pagination from '@/components/shared/Pagination/Pagination';
import FilterTable from '@/components/shared/Icons/FilterTable.vue';

export default {
	name: 'ContractManagement',
	components: {
		Content,
		MobileFilter,
		WebFilter,
		ColumnsModal,
		FilterList,
		FilterTable,
		ContractRow,
		SpinnerIcon,
		Pagination,
	},
	data: () => ({
		windowWidth: window.innerWidth,
		showEditHeaderModal: false,
		selectedTable: null,
		page: 1,
		pageFromStore: 1,
		lastQuery: null,
		lastRoute: null,
		sortColumn: null,
		sortOrder: 'ASC',
		currentFilters: '',
	}),
	created() {
		const companyCache = this.companyManagementFilterCache.value;
		const contractCache = this.contractsManagementFilterCache.value;
		const marketCache = this.marketManagementFilterCache.value;
		const purchaseOrderCache = this.poManagementFilterCache.value;

		if (
			(!companyCache || Object.keys(companyCache).length <= 0) &&
			(!contractCache || Object.keys(contractCache).length <= 0) &&
			(!marketCache || Object.keys(marketCache).length <= 0) &&
			(!purchaseOrderCache || Object.keys(purchaseOrderCache).length <= 0)
		) {
			this.refreshContracts();
		}

		this.getPreferences({ vm: this });
		this.refreshFilters();
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		});
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		...mapActions('contractManagement', {
			getContracts: CONTRACT_MANAGEMENT_REQUEST,
			getPreferences: CONTRACT_PREFERENCES_REQUEST,
			downloadFilteredContracts: CONTRACT_DOWNLOAD_REQUEST,
		}),
		...mapActions('filters', {
			getContractFilter: CONTRACTS_FILTER_REQUEST,
			getCompanyFilter: COMPANY_FILTER_REQUEST,
			getMarketFilter: MARKET_FILTER_REQUEST,
			getPoFilter: PO_FILTER_REQUEST,
		}),
		onResize() {
			this.windowWidth = window.innerWidth;
		},
		changePage(params) {
			let query = this.lastQuery;

			query = query.replace(`&page=${this.page}`, `&page=${params.params}`);
			this.lastQuery = query;

			this.page = params.params;
			this.getContracts({ vm: this, params: query, withoutLoading: true });
		},
		updateFilters(queryString) {
			this.currentFilters = queryString;
		},
		refreshContracts(params, withoutLoading) {
			let queryString = params ? params + '&limit=10&' : 'limit=10&';

			if (this.sortColumn) {
				queryString += `sortBy=${this.sortColumn}&sortOrder=${this.sortOrder}&`;
			}

			queryString += `page=${this.page}&`;

			this.lastQuery = queryString;
			this.getContracts({ vm: this, params: queryString, withoutLoading });
		},
		toggleSort(column) {
			if (this.sortColumn === column) {
				this.sortOrder = this.sortOrder === 'ASC' ? 'DESC' : 'ASC';
			} else {
				this.sortColumn = column;
				this.sortOrder = 'ASC';
			}

			this.refreshContracts(this.currentFilters);
		},
		refreshFilters() {
			this.getContractFilter({ vm: this }).then((res) => {
				if (res.status == 200) {
					this.$refs.filterManagement.setCachedContracts();
				}
			});
			this.getCompanyFilter({ vm: this }).then((res) => {
				if (res.status == 200) {
					this.$refs.filterManagement.setCachedCompany();
				}
			});
			this.getMarketFilter({ vm: this }).then((res) => {
				if (res.status == 200) {
					this.$refs.filterManagement.setCachedMarket();
				}
			});
			this.getPoFilter({ vm: this }).then((res) => {
				if (res.status == 200) {
					this.$refs.filterManagement.setCachedPo();
				}
			});
		},
		closeAllTables(id) {
			this.selectedTable = id;

			if (this.contractsList && this.contractsList.length) {
				this.contractsList.forEach((contract) => {
					if (id != contract.id) {
						this.$refs[`contract-row-${contract.id}`][0].closeTable();
					}
				});
			}
		},
		openLastRow() {
			const page = this.lastRowOpened.page;
			const contractId = this.lastRowOpened.id;
			this.pageFromStore = page;
			this.page = page;

			if (this.contractsList && this.contractsList.length) {
				this.contractsList.forEach((contract) => {
					if (contractId == contract.id) {
						this.$refs[`contract-row-${contract.id}`][0].openTable();
					}
				});
			}
		},
		handleModal() {
			this.showEditHeaderModal = !this.showEditHeaderModal;
		},
		setPageOne() {
			if (this.contractsList.length != 0 && !this.isLoadingContracts) {
				this.page = 1;
				this.$refs.pagination.setPageOne();
			}
		},
		downloadContracts() {
			this.downloadFilteredContracts({ vm: this, params: this.lastQuery }).then((res) => {});
		},
	},
	computed: {
		...mapGetters('contractManagement', [
			'contractList',
			'lastRowOpened',
			'isLoadingContracts',
			'isLoadingPrefereces',
			'isLoadingDownload',
			'userPreferences',
		]),
		...mapGetters('filters', [
			'companyManagementFilterCache',
			'contractsManagementFilterCache',
			'poManagementFilterCache',
			'marketManagementFilterCache',
		]),
		isWeb() {
			return this.windowWidth > 992;
		},
		contractsList() {
			const contracts = this.contractList.items != undefined ? this.contractList.items : [];
			return !contracts.length ? [] : contracts;
		},
		pagination() {
			const pagination =
				this.contractList.meta != undefined
					? this.contractList.meta
					: { currentPage: 1, totalPages: 1 };

			return pagination;
		},
	},
	watch: {
		isLoadingContracts(newValue) {
			if (!newValue) {
				if (this.lastRowOpened.id != undefined) {
					setTimeout(() => {
						return this.openLastRow();
					}, 250);
				}
			}
		},
	},
};
</script>

<style lang="scss">
@import 'ContractManagement.scss';
</style>
