<template>
	<div class="filter">
		<div class="filter-action" v-if="!isWeb">
			<div class="filter-action__text">{{ $t('buttons.filters') }}:</div>
			<button class="btn-outline" @click="clearFilters()">
				<ClearIcon /> {{ $t('buttons.clear_filter') }}
			</button>
		</div>
		<div class="filter-container">
			<div class="filter-row">
				<ContractInput ref="contractInput" :isWeb="isWeb" @filterRequest="filterRequest" />
				<CompanyInput
					ref="companyInput"
					v-if="verifyActive('company')"
					:isWeb="isWeb"
					@filterRequest="filterRequest"
				/>
				<POInput ref="purchaseOrderInput" :isWeb="isWeb" @filterRequest="filterRequest" />
			</div>
			<div class="filter-row">
				<MarketInput ref="marketInput" :isWeb="isWeb" @filterRequest="filterRequest" />
				<ContractInitial
					ref="contractInitial"
					v-if="refreshCalendars && verifyActive('contractDate')"
					:isWeb="isWeb"
					@filterRequest="filterRequest"
				/>
				<ContractEnd
					ref="contractEnd"
					v-if="refreshCalendars && verifyActive('contractDate')"
					:isWeb="isWeb"
					@filterRequest="filterRequest"
				/>
			</div>
			<div class="filter-row filter-row__end">
				<button class="btn-outline" type="button" @click="clearCachedFilters()" v-if="isWeb">
					{{ $t('buttons.clear_filter') }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { CLEAR_CACHED_FILTERS } from '@/store/common/filters/actions';
import ContractInput from './Inputs/ContractInput/ContractInput.vue';
import CompanyInput from './Inputs/CompanyInput/CompanyInput.vue';
import ReleaseInitial from './Inputs/ReleaseInitial/ReleaseInitial.vue';
import ReleaseEnd from './Inputs/ReleaseEnd/ReleaseEnd.vue';
import ContractInitial from './Inputs/ContractInitial/ContractInitial.vue';
import ContractEnd from './Inputs/ContractEnd/ContractEnd.vue';
import StatusInput from './Inputs/StatusInput/StatusInput.vue';
import MarketInput from './Inputs/MarketInput/MarketInput.vue';
import POInput from './Inputs/POInput/POInput.vue';
import ClearIcon from '@/components/shared/Icons/ClearIcon.vue';

export default {
	name: 'FilterList',
	components: {
		ContractInput,
		CompanyInput,
		ReleaseInitial,
		ReleaseEnd,
		ContractInitial,
		ContractEnd,
		StatusInput,
		MarketInput,
		POInput,
		ClearIcon,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		filter: {
			contractDateStart: null,
			contractDateEnd: null,
			releaseDateStart: null,
			releaseDateEnd: null,
			status: null,
			contractNumber: null,
			contract: null,
			purchaseOrder: null,
			market: null,
		},
		refreshCalendars: true,
	}),
	mounted() {
		this.clearFilters();
		this.filterCachedRequest();
	},
	methods: {
		...mapActions('filters', {
			clearStoreFilters: CLEAR_CACHED_FILTERS,
		}),
		verifyActive(key) {
			const preferences = this.userPreferences;
			let show = true;

			if (!preferences.header.length) {
				return true;
			}

			preferences.header.forEach((item) => {
				if (item.name == key && !item.active) {
					show = false;
				}
			});

			return show;
		},
		filterRequest(param) {
			this.filter[param.type] = param.value ? param.value.id : null;
			let queryString = this.createQueryString();

			if (!param.value || !param.value.length) {
				this.$emit('setPageOne');
			}

			this.$emit('updateFilters', queryString);
			this.$emit('refreshContracts', queryString);
		},
		filterCachedRequest() {
			const companyCache = this.companyManagementFilterCache.value;
			const contractCache = this.contractsManagementFilterCache.value;
			const initialReleaseDateCache = this.initialReleaseDateCache.value;
			const endReleaseDateCache = this.endReleaseDateCache.value;
			const contractInitialDateCache = this.contractInitialDateCache.value;
			const contractEndDateCache = this.contractEndDateCache.value;
			const contractStatusCache = this.contractStatusCache.value;
			const marketCache = this.marketManagementFilterCache.value;
			const purchaseOrderCache = this.poManagementFilterCache.value;
			if (marketCache && Object.keys(marketCache).length) {
				this.filter['market'] = marketCache.id;
			}
			if (purchaseOrderCache && Object.keys(purchaseOrderCache).length) {
				this.filter['purchaseOrder'] = purchaseOrderCache.id;
			}

			if (companyCache && Object.keys(companyCache).length) {
				this.filter['companyId'] = companyCache.id;
			}

			if (contractCache && Object.keys(contractCache).length) {
				this.filter['contractNumber'] = contractCache.id;
			}

			if (initialReleaseDateCache.id != undefined) {
				this.filter['releaseDateStart'] = initialReleaseDateCache.id;
			}

			if (endReleaseDateCache.id != undefined) {
				this.filter['releaseDateEnd'] = endReleaseDateCache.id;
			}

			if (contractInitialDateCache.id != undefined) {
				this.filter['contractDateStart'] = contractInitialDateCache.id;
			}

			if (contractEndDateCache.id != undefined) {
				this.filter['contractDateEnd'] = contractEndDateCache.id;
			}

			if (contractStatusCache.id != undefined) {
				this.filter['status'] = contractStatusCache.id;
			}

			let queryString = this.createQueryString();
			this.$emit('refreshContracts', queryString);
		},
		createQueryString() {
			let {
				contractDateStart,
				contractDateEnd,
				releaseDateStart,
				releaseDateEnd,
				status,
				contractNumber,
				companyId,
				market,
				purchaseOrder,
			} = this.filter;

			const query = new URLSearchParams();

			if (contractDateStart) query.set('contractDateStart', contractDateStart);
			if (contractDateEnd) query.set('contractDateEnd', contractDateEnd);
			if (releaseDateStart) query.set('releaseDateStart', releaseDateStart);
			if (releaseDateEnd) query.set('releaseDateEnd', releaseDateEnd);
			if (status) query.set('statusSequence', status);
			if (contractNumber) query.set('contractNumber', contractNumber);
			if (companyId) query.set('companyId', companyId);
			if (market) query.set('market', market);
			if (purchaseOrder) query.set('purchaseOrder', purchaseOrder);

			return query.toString();
		},
		clearCachedFilters() {
			this.clearStoreFilters();
			this.clearFilters();
		},
		clearFilters() {
			this.$refs.contractInput.clearInput();
			this.$refs.companyInput.clearInput();
			this.$refs.status.clearInput();
			this.$refs.marketInput.clearInput();
			this.$refs.purchaseOrderInput.clearInput();

			if (this.verifyActive('releaseDate')) {
				this.$refs.releaseInitial.clearInput();
				this.$refs.releaseEnd.clearInput();
			}

			if (this.verifyActive('contractDate')) {
				this.$refs.contractInitial.clearInput();
				this.$refs.contractEnd.clearInput();
			}

			this.filter = {
				contractDateStart: null,
				contractDateEnd: null,
				releaseDateStart: null,
				releaseDateEnd: null,
				status: null,
				contractNumber: null,
				contract: null,
				purchaseOrder: null,
				market: null,
			};

			this.$emit('refreshContracts', null);
			this.$emit('refreshFilters');
		},
		setCachedContracts() {
			this.$refs.contractInput.setCachedInput();
		},
		setCachedCompany() {
			this.$refs.companyInput.setCachedInput();
		},
		setCachedMarkets() {
			this.$refs.marketInput.setCachedInput();
		},
		setCachedPo() {
			this.$refs.purchaseOrderInput.setCachedInput();
		},
	},
	computed: {
		...mapGetters('contractManagement', ['userPreferences', 'isLoadingPrefereces']),
		...mapGetters('user', ['userLang']),
		...mapGetters('filters', [
			'companyManagementFilterCache',
			'contractsManagementFilterCache',
			'initialReleaseDateCache',
			'endReleaseDateCache',
			'contractInitialDateCache',
			'contractEndDateCache',
			'contractStatusCache',
			'poManagementFilterCache',
			'marketManagementFilterCache',
		]),
	},
	watch: {
		userLang(newValue) {
			if (newValue) {
				this.refreshCalendars = false;
				setTimeout(() => {
					this.refreshCalendars = true;
				}, 100);
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import 'FilterList.scss';
</style>
