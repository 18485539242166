<template>
	<div
		class="doc-row"
		:class="{ active: statusClass == 'disable', selected: templateSelected, open: showRow }"
		@click="selectTemplate()"
	>
		<div class="doc-row__header">
			<div class="doc-row__column">
				<span class="doc-row__column--title">
					{{ $t('modules.doc_instruction.doc_row.name') }}
				</span>
				<span class="doc-row__column--subtitle"> {{ document.nmTemplate }}</span>
			</div>
			<div class="doc-row__column">
				<span class="doc-row__column--title">
					{{ $t('modules.doc_instruction.doc_row.update_date') }}
				</span>
				<span class="doc-row__column--subtitle"> {{ formateDate(document.updatedDate) }}</span>
			</div>
			<div class="doc-row__column">
				<button class="doc-row__column--btn" :class="statusClass" @click.stop="changeStatus()">
					{{ $t(buttonTitle) }}
				</button>
			</div>
			<div class="doc-row__column">
				<div class="doc-row__column__mobileTitle">
					<span class="doc-row__column__mobileTitle--subtitle"> {{ document.name }}</span>
					<span class="doc-row__column__mobileTitle--status" :class="statusClassForRow">
						{{ $t(buttonTitleForRow) }}</span
					>
				</div>
				<button class="doc-row__column__mobileAction" @click.stop="handleRow">
					<ArrowDown :class="showRow ? 'rotate' : ''" :stroke="'2'" :color="'#234057'" />
				</button>
			</div>
		</div>

		<div class="doc-row__body">
			<div class="doc-row__column">
				<span class="doc-row__column--title">
					{{ $t('modules.doc_instruction.doc_row.name') }}
				</span>
				<span class="doc-row__column--subtitle"> {{ document.nmTemplate }}</span>
			</div>
			<div class="doc-row__column">
				<span class="doc-row__column--title">
					{{ $t('modules.doc_instruction.doc_row.update_date') }}
				</span>
				<span class="doc-row__column--subtitle"> {{ formateDate(document.updatedDate) }}</span>
			</div>
			<div class="doc-row__column">
				<span class="doc-row__column--title"> Status </span>
				<span class="doc-row__column--subtitle" :class="statusClassForRow">
					{{ $t(buttonTitleForRow) }}</span
				>
			</div>
			<div class="doc-row__column">
				<button class="doc-row__column--btn" :class="statusClass" @click.stop="changeStatus()">
					{{ $t(buttonTitle) }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import {
	SAVE_TEMPLATE,
	CLEAN_TEMPLATE,
} from '@/store/modules/docInstruction/selectInstruction/actions';
import ArrowDown from '@/components/shared/Icons/ArrowDown.vue';

export default {
	name: 'DocTemplateRow',
	components: {
		ArrowDown,
	},
	props: {
		document: {
			type: Object,
			default: () => ({}),
		},
	},
	data: () => ({
		showRow: false,
	}),
	created() {},
	mounted() {},
	beforeDestroy() {},
	computed: {
		...mapGetters('selectInstruction', ['selectedTemplateData']),
		statusClass() {
			if (!this.document.status) {
				return 'active';
			}
			return 'disable';
		},
		statusClassForRow() {
			if (this.document.status) {
				return 'active';
			}
			return 'disable';
		},
		buttonTitle() {
			if (this.document.status) {
				return 'buttons.disable';
			}
			return 'buttons.active';
		},
		buttonTitleForRow() {
			if (this.document.status) {
				return 'buttons.active';
			}
			return 'buttons.disable';
		},
		templateSelected() {
			if (this.selectedTemplateData) {
				const selectedTemplate = this.selectedTemplateData;
				return selectedTemplate.cdTemplate === this.document.cdTemplate;
			}
			return false;
		},
	},
	methods: {
		...mapActions('selectInstruction', {
			saveTemplate: SAVE_TEMPLATE,
			clearTemplate: CLEAN_TEMPLATE,
		}),
		selectTemplate() {
			const selectedTemplate = this.selectedTemplateData;

			if (this.document.status) {
				if (!selectedTemplate || selectedTemplate.cdTemplate !== this.document.cdTemplate) {
					this.clearTemplate();
					this.saveTemplate(this.document);
				} else {
					this.clearTemplate();
				}
			}
		},
		formateDate(value) {
			return value && value !== null ? moment(value).format('DD/MM/YYYY') : '';
		},
		changeStatus() {
			const statusData = {
				vm: this,
				cdTemplate: this.document.cdTemplate,
				data: { cdStatus: this.document.status ? 0 : 1 },
			};

			if (this.document.status && this.templateSelected) {
				this.clearTemplate();
			}

			this.$emit('changeStatus', statusData);
		},
		handleRow() {
			this.showRow = !this.showRow;
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'DocTemplateRow.scss';
</style>
