<template>
	<GenericMultiselect
		class="contract-company-multiselect"
		ref="company"
		:options="companyOptions"
		:placeholder="$t('modules.contract_management.filter.company_placeholder')"
		:fullWidth="!isWeb"
		:searchable="true"
		:needType="true"
		:showIcon="false"
		:infiniteScroll="true"
		:type="'companyId'"
		@changeInput="changeInput"
		@requestByChange="requestByChange"
		@nextPage="nextPage"
	/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	CONTRACTS_FILTER_REQUEST,
	COMPANY_FILTER_REQUEST,
	MARKET_FILTER_REQUEST,
	PO_FILTER_REQUEST,
	SAVE_MANAGEMENT_COMPANY_FILTER,
} from '@/store/common/filters/actions';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';

export default {
	name: 'CompanyInput',
	components: {
		GenericMultiselect,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		page: 1,
	}),
	mounted() {},
	methods: {
		...mapActions('filters', {
			getCompanyFilter: COMPANY_FILTER_REQUEST,
			getContractFilter: CONTRACTS_FILTER_REQUEST,
			getMarketFilter: MARKET_FILTER_REQUEST,
			getPoFilter: PO_FILTER_REQUEST,
			saveCompanyFilter: SAVE_MANAGEMENT_COMPANY_FILTER,
		}),
		changeInput(param) {
			this.page = 1;
			this.saveCompanyFilter(param);

			if (param && param.value) {
				const companyId = param.value.id;
				const query = new URLSearchParams();

				query.set('companyId', companyId);
				query.set('limit', '20');
				query.set('page', '1');

				this.getPoFilter({ vm: this, params: query });
				this.getContractFilter({ vm: this, params: query });
				this.getMarketFilter({ vm: this, params: query });
			} else {
				this.getPoFilter({ vm: globalThis });
				this.getMarketFilter({ vm: this });
				this.getContractFilter({ vm: this });
			}

			this.$emit('filterRequest', param);
		},
		requestByChange(param) {
			let query = this.createQueryString(param);

			this.getCompanyFilter({ vm: this, params: query });
		},
		nextPage() {
			const meta = this.companyFilterMeta;

			if (meta.totalPages == this.page) {
				return;
			}

			this.page++;
			let query = this.createQueryString();

			this.getCompanyFilter({ vm: this, params: query });
		},
		createQueryString(name) {
			const query = new URLSearchParams();

			if (name) query.set('name', name);
			if (name) this.page = 1;

			query.set('limit', '20');
			query.set('page', this.page);

			return query.toString();
		},
		clearInput() {
			this.$refs.company.setValue(null);
		},
		filterContract(param) {
			const companyIds = param.id;
			const query = new URLSearchParams();

			query.set('companyIds', companyIds);
			query.set('limit', '20');
			query.set('page', '1');
			this.getContractFilter({ vm: this, params: query });
			this.getMarketFilter({ vm: this, params: query });
			this.getPoFilter({ vm: this, params: query });
		},
		setCachedInput() {
			const cachedFilter = this.companyManagementFilterCache.value;

			if (!cachedFilter || Object.keys(cachedFilter).length <= 0) {
				return;
			}

			this.$refs.company.setValue(cachedFilter);
			this.filterContract(cachedFilter);
		},
	},
	computed: {
		...mapGetters('filters', [
			'companyFilter',
			'companyFilterMeta',
			'companyManagementFilterCache',
			'isLoadingCompanyFilter',
		]),
		companyOptions() {
			const rows = this.companyFilter != undefined ? this.companyFilter : [];

			return !rows.length ? [] : rows;
		},
	},
};
</script>

<style lang="scss">
@import 'CompanyInput.scss';
</style>
