import tableCN from './contractTable/table-cn';

export default {
	buttons: {
		cancel: '取消',
		login: '登录',
		next: '下一步',
		ok: '是',
		save: '保存',
		save_password: '保存密码',
		filter: '选择',
		filters: '选择',
		clear_filter: '清除筛选',
		add: '增加',
		delete: '删除',
		inative: '禁用',
		edit_column: '编辑列',
		confirm: '确认',
		back: '返回',
		keep_inactive: '失效',
		keep_active: '有效',
		previous: '以前的',
		seeMore: '更多可见',
		seeLess: '更少可见',
		continue: '继续',
		disable: '使无效',
		active: '有效',
		new_template: '新模板',
		save_template: '保存模板',
		change_template: '改变模板',
		add_bank: '增加银行',
		notify: '通知',
		edit: '编辑',
		disapprove: '不批准',
		remove: '移除',
	},
	errors: {
		login_required: '登陆要求',
		password_required: '密码要求',
		empty_data: '空白数据',
		dashboard_empty_data: '没有更新',
		fill_fields: '填充所有区域',
		email_invalid: '无效邮件',
		passwords_rules:
			'请检查密码必须包含8个字符。至少有一个大写字母，一个小写字母，一个数字和一个特殊字符',
		passwords_dismatch: '密码错误',
		fixed_column: '不能取消列',
		no_options: '无选择发现',
		no_items: '无项目发现',
		get_items_error: '加载错误',
		documents_error: '无文件发现',
		template_name_required: '模板名称必填',
	},
	sidebar: {
		user_management: '用户管理',
		contract_management: '合同管理',
		contract_details: '合同明细',
		dashboard: '控制面板',
		load_management: '装载管理',
		doc_management: '文件管理',
		financial_management: '财务管理',
		doc_instruction: '文件指令',
	},
	header: {
		user_management: '用户管理',
		contract_management: '合同管理',
		contract_details: '合同明细',
		header_contracts: '合同',
		header_sequences: '序号',
		header_loads: '装载',
		header_po: '客户订单号',
		dashboard: '控制面板',
		load_management: '装载管理',
		customer_company: '客户',
		doc_management: '文件管理',
		financial_management: '财务管理',
		doc_instruction: '文件指令',
		new_template: '新模板',
		edit_template: '编辑模板',
	},
	months: {
		jan: '一月',
		feb: '二月',
		mar: '三月',
		apr: '四月',
		may: '五月',
		jun: '六月',
		jul: '七月',
		aug: '八月',
		sep: '九月',
		oct: '十月',
		nov: '十一月',
		dec: '十二月',
	},
	contract_status: {
		contract: '签发的合同',
		load: '建立的装载量',
		process: '计划装载量',
		container: '已装运的柜子',
		invoice: '已发船/已开发票',
		finished: '已传递',
	},
	contract_step: {
		creation: '正在创建合同',
		released_seara_restriction: '已发布 - Seara 限制',
		released_customer_restriction: '已发布-客户端限制',
		released: '已放行-客户限制',
		programmed: '已计划',
		planned: '已计划的船',
		billed: '已装货柜',
		issued_document: 'HC已签发',
		gate_in: '起始门',
		pre_payment_pending: '等待预付',
		rid: '即将过期',
		draft_bl: '提单草稿件',
		boarded: '已发船',
		documentation_issued: '已签发文件',
		documentation_sended: '已发送文件',
		documentation_delivered: '已寄出文件',
		shipped_origin: '已发船',
		transshipment_port: '转运港口',
		landed_destination: '卸运目的港',
		gate_out: '在目的地出场',
		empty_return: '返还空箱',
		opened: '打开的',
		opened_delay: '过期的',
		partial_paid: '部分已付',
		total_paid: '全部已付',
		finished: '完成',
		processing: '正在进行',
		in_production: '正在生产',
		in_loading: '装载中',
		delivered_in_pod: '传送目的港',
	},
	modules: {
		login: {
			login_label: '登录',
			password_label: '密码',
			forgot_password: '忘记你的密码？',
			forgot_password_title: '忘记我的密码？',
			forgot_password_text: '忘记密码？邮件收到重置密码的链接',
			email_sended: '设置密码邮件已发送',
			code_sended: '安全码发送',
			verify_email: '请检查你的邮件并输入6位数安全码完成登录',
		},
		resetPassword: {
			new_password_label: '新密码',
			confirm_password_label: '确认新密码',
			new_password_title: '新密码',
			new_password_text: '现在你可以更改你的密码',
			password_changed_succesfully: '密码更改成功',
			rules_title: '新密码条件',
			rule_uppercase: '至少一个大写字母',
			rule_lowercase: '至少一个小写字母',
			rule_number: '至少一个数字',
			rule_length: '至少8个字符',
			rule_special: '至少一个特殊字符(!, @, #, $, %, *, ., 等等)',
		},
		user_management: {
			name_placeholder: '用户名',
			email_placeholder: '邮箱',
			company_placeholder: '公司名称',
			status_placeholder: '用户状态',
			profiles: {
				client: '客户',
				intermediary: '中间者',
				internal: '内部',
			},
			status: {
				active: '有效',
				suspended: '无效',
			},
			requests_messages: {
				user_created: '用户创建成功',
				user_updated: '用户更新成功',
				user_deleted: '用户已成功停用。',
				user_reactived: '用户已成功重新激活。',
			},
			modal: {
				new_user: '注册新用户',
				edit_user: '编辑用户',
				delete_question: '您确定要停用此用户吗？',
				reactive_question: '你确定要重新激活这个用户吗？',
				name_label: '名字',
				name_placeholder: '全名',
				company_label: '全名',
				company_placeholder: '公司名字',
				email_label: '邮箱',
				email_placeholder: '你的邮箱',
				phone_label: '手机号',
				phone_placeholder: '电话号码',
				profile_label: '简介',
				profile_placeholder: '用户简介',
				status_label: '状态',
				status_placeholder: '用户状态',
				company_manager_label: '公司管理',
				company_manager_placeholder: '进入公司名字',
				confirmation_active:
					'你想激活此用户？当激活用户的时候，他/她将收到一个欢迎邮件，你想进行吗？',
			},
			table: {
				name: '名字',
				email: '邮箱',
				code: '编码',
				status: '状态',
			},
		},
		contract_management: {
			table: tableCN,
			filter: {
				start_date: '第一个日期',
				end_date: '最后一个日期',
				contract_start_date: '合同起始日期',
				contract_end_date: '合同结束日期',
				contract_placeholder: '合同',
				company_placeholder: '公司',
				transport_placeholder: '船/卡车',
				product_placeholder: '产品名称',
				purchaseOrder_placeholder: '采购订单',
				market_placeholder: '市场',
			},
			column_modal: {
				title_modal: '编辑列',
				header_columns: '合同',
				details_columns: '装载号码',
				active_status: '有效',
				disable_status: '无效',
				exit_question: '你想要关闭并不保存修改吗',
			},
			requests_messages: {
				preferences_updated: '成功保存列',
			},
		},
		contract_detail: {
			card: {
				title_tracking: '合同管理',
				title_contract: '合同管理',
				title_sequence: '序号',
				title_load: '装载号',
				title_document: '文件管理',
			},
			contract: {
				companyName: '公司',
				competenceMonth: '所属时间 月月/年年年年',
				contractDate: '合同日期',
				contractNumber: '合同号码',
				incoterm: '国贸条规',
				market: '市场',
				paymentMethod: '付款方式',
				pod: '目的港',
				priceType: '货币',
				purchaseOrder: '订单号',
				purchaseOrderClient: '客户订单号',
				releaseDate: '放行日期',
				totalPendingVolume: '未决定的量',
				totalVolume: '总量',
				traderName: '销售',
				approved: '已批准',
				disapproved: '已拒绝',
				approve: '已批准',
				disapprove: '已拒绝',
				preposition: 'at',
			},
			sequence: {
				brand: '品牌',
				cneCode: 'Cod. Letter of Credit',
				contractSequence: '序号',
				dsInternationalOfc: '货物描述',
				endShipDate: '最后装船日期',
				groupingDescription: 'Grouping',
				permissionImt: '进口许可证',
				permissionImtDate: '逾期日期',
				releaseSequenceDate: '放行日期（序号）',
				sku: '产品名称',
				startShipDate: '第一个装船日期',
				statusSequence: '状态序号',
				totalVolume: '量',
				unitPrice: '单价',
				weeksToShip: '装船周',
				initials: '产品名称',
			},
			load: {
				invoicedDate: '已出发票日期（工厂）',
				loadNumber: '装转号码',
				loadPlace: '装载地',
				productionDate: '生产日期',
				totalGrossWeight: '毛重',
				totalKg: '总重量',
				totalVolume: '总量',
			},
			documents: {
				awbCourier: '快递单号',
				billLanding: 'BL/CRT/AWB',
				billLandingDate: 'BL/CRT/AWB Date',
				blDate: 'BL Date',
				courierDate: '快递日期',
				csiIssuanceDate: '卫生证日期',
				invoiceNumber: '发票',
				courierCompany: '快递公司',
				bankAwbCourier: '快递单号（银行）',
				bankCourierDate: '快递日期（银行）',
				bankCourierCompany: '快递公司（银行）',
			},
			table: {
				title_international: '船/卡车',
				title_maritime: '船公司',
				title_mercosul: 'Mercosul运输',
			},
			internationalTransport: {
				boardingType: '船运通过',
				origin: '装运港',
				destination: '目的港',
				expectedEtdDate: '装运港发船日期',
				expectedEtbDate: '船到达起运港日期',
				realAtdDate: '目的港到港日期',
				realAtbDate: '目的港到港日期',
			},
			maritimeTransport: {
				provider: '船公司',
				vessel: '船/卡车',
				container: '货柜',
				bookingNumber: '订舱号',
			},
			mercosurTransport: {
				shippingCompany: 'Shipping Company',
				vehiclePlate: 'Shipping Plate',
				localization: 'Localization',
				borderCrossingDate: 'Border crossing date',
			},
		},
		dashboard: {
			table: {
				title: '最新状态更新',
				contract: '合同',
				sequence: '序号',
				load: '装载号',
				transport: '船/卡车',
				invoice: '发票',
				code: '产品型号',
				status: '状态',
				volume: '净重',
			},
			charts: {
				analysis_title: '分析中',
				finance_title: '财务管理',
				rounded: {
					in_process: 'In process',
					release_retrictions: ['放行', '客户限制'],
					released: '已放行',
					open: '打开的',
					open_delay: ['逾期的', '逾期的'],
					partial_paid: '部分已付',
					paid: '全部已付',
					finished: '已完成',
				},
			},
			operation_chart: {
				operation_title: '在运行',
				in_production: '在生产',
				in_charging: '装载中',
				gate_in: '进场',
				wait_prepayment: '等待预付',
				embedded_doc: '文件传送',
				sent_doc: '文件发送',
				delivered: '已发船',
				delivered_pod: '传送目的港',
				gate_out: '出场',
				empty_returned: '空箱返还',
			},
		},
		load_management: {
			header: {
				truck: '卡车',
				ship: '船',
			},
			filter: {
				contract: '合同/序号',
				po: '客户订单号',
				load: '装载号',
				transport: '船/卡车',
				container: '货柜',
				product: '货品描述',
				ets: '发船日期/船到达起运港日期',
				eta: '到目的港日期',
				invoice: '发票',
			},
			sections: {
				inProduction: '已计划',
				loading: '装载中（设备）',
				gateInOrigin: '进场',
				shipped: '已发船',
				deliveredToPOD: '传送目的港',
				gateOutDestination: '出场',
				deliveredToDestination: '已送达',
				border: '边境',
				inTransit: '在途中',
				ship: {
					inProduction: '生产中',
					loading: '装载中',
					gateInOrigin: '原点入闸',
					shipped: '已发货',
					deliveredToPOD: '交付至POD',
					gateOutDestination: '目的地出闸',
					deliveredToDestination: '交付至目的地',
				},
				truck: {
					inProduction: '生产中',
					loading: '装载中',
					gateInOrigin: '原点入闸',
					shipped: '运输中',
					deliveredToPOD: '已发送',
					gateOutDestination: '目的地出闸',
					deliveredToDestination: '交付至目的地',
					sent: '已發送',
				},
			},
			cargoTable: {
				cargo: '装载号',
				container: '货柜',
				invoice: '发票',
				vehicle: '船/卡车',
				POD: '目的港',
				gateIn: '进场',
				ets: '到港日期/船到达起运港日期',
				eta: '到目的港日期',
				gateOut: '出场',
				netWeight: '净重',
			},
			contractTable: {
				sequence: '序号',
				acronym: '物品',
				description: '描述',
				brand: '品牌',
				sif: '厂号',
				volume: '纸箱数量',
				weight: '净重',
			},
			map: {
				contracts: '合同',
				products: '产品',
				loads: '装载量',
				weigth: '净重',
				awaiting_payment: '等待预付',
				state: '状态',
				city: '城市',
				shipCard: {
					vehicle: '车辆',
					load: '货物',
					contract: '合同',
					sku: 'SKU',
				},
				truckCard: {
					vehiclePlate: '车辆牌照',
					plannerDate: '计划日期',
					invoiceDate: '发票日期',
					loadDate: '装载日期',
					realAtdDate: '出发日期',
					invoiceNumber: '發票號碼',
				},
			},
		},
		financial_management: {
			titles: {
				pre_payment: '预付',
				invoice: '发票',
				overdue: '逾期',
				settle: '到期付款',
				credit: '信用情况',
				limit: '目前额度',
				balance_available: '可用余额',
			},
			filter: {
				market: '市场',
				init_date: '起始日期:',
				end_date: '最后日期:',
				currency: '货币',
				status: '状态',
			},
			status: {
				paid: '已付',
				awaiting_paid: '等待付款',
				overdue: '逾期',
			},
			table: {
				due_date: '逾期日期',
				contract: '合同',
				invoice: '发票',
				total_invoice: '总金额',
				po: '订单号',
				currency: '货币',
				status: '状态',
				total: '总金额',
				payment: '付款',
				balance: '尾款',
			},
			currency: {
				dolar: 'DOLLAR',
				euro: 'EURO',
				yen: 'YEN',
			},
		},
		doc_management: {
			filter: {
				init_date: '起始日期',
				end_date: '最后日期',
				invoice: '发票',
				contract: '合同',
				sequence: '序号',
				po: '订单号',
				container: '货柜',
				transport: '船/卡车',
				pod: '目的港',
				load: '装载',
				acronym: '条目',
				description: '产品描述',
			},
			doc_row: {
				invoice: '发票',
				vehicle: '船/卡车',
				pol: '起始港',
				atsPol: '船到达起运港日期',
				pod: '目的港',
				etaPod: '到目的港日期',
				plTon: '量',
				value: '总价值',
				download: '下载',
				payment_status: '付款情况',
				approval_status: '批准状态',
				shipment_status: '发送情况',
			},
			doc_detail: {
				contract: '合同',
				condition: '付款条件',
				term: '付款时期',
				type: '文件（指定的）',
				publication: '上传日期',
				responsible: '上传人',
				approval: '批准日期',
				approver: '批准人',
				observation: '评论',
				download: '下载',
				status: '状态',
				not_sended: '没有发送',
				pending: '未决定的',
				viewed: '看到的',
				approved: '批准的',
				repproved: '拒绝的',
				cancelled: '取消的',
			},
			doc_table: {
				container: '货柜',
				cargo: '装载号码',
				po: '订单号码',
				plton: '量',
				sequence: '序号',
				courier: '快递公司',
				awb: '提单',
				shipping: '发送日期',
				destiny: '目的地',
				delivery: '传递日期',
				status: '状态',
				notSent: '未发送',
				delivered: '已送达',
				inTransit: '在运输中',
				bank: '银行',
				client: '顾客',
				invalidDate: '无效日期',
			},
			reproval_modal: {
				title: '文件（指定的）',
				subtitle: '请输入一条关于不批准的评论',
				comment: '评论',
				approved: 'Document approved successfully!',
				repproved: 'Document rejected successfully!',
			},
		},
		doc_instruction: {
			filter: {
				pod: '目的港',
				acro_description: '货品描述',
				contract: 'Contract / Sequence',
			},
			steps: {
				title: '指令名称',
				selectTheContract: '选择你需要选择/注册文件指令的合同',
				selectDocumentaryInstruction: '选择你想要指定或者新建的文件指令',
				confirmTheData: '确认你文件指令的明细',
				enterDestination: '通知正本文件的目的地（完整地址）',
				notFound: '没有发现',
				step_one_description: '选择你想要选择/注册文件指令的合同',
				step_two_description: '选择你想要指定或者新建的文件指令',
				step_three_description: '确认你文件指令的明细',
				step_four_description: '通知正本文件的目的地（完整地址）',
				step_by_step: 'Step by Step',
				mobile_step: 'Step {step} of 4',
			},
			doc_row: {
				code: 'Code',
				name: 'Name',
				update_date: '最后一次更新',
				contract: '合同',
				customer: '客户',
			},
			doc_table: {
				sequence: '序号',
				pod: '目的港',
				acro: '货品描述',
			},
			inactive_templates_title: '无效模板',
			select_doc_instruction: '选择文件指令',
			selected_contracts_title: '选择合同/序号',
			consignee_modal: {
				header: '收货人/被通知人',
				name: '名字（公司或个人）',
				email: '邮箱',
				phone: '电话',
				address: '地址',
				country: '国家',
				state: '州',
				city: '城市',
				zipcode: '邮编',
				tax: '税号',
				summary: 'Summary',
				characters: 'Characters',
			},
			documents_package: {
				bank: '银行名称',
				otherRecipient: '其他收件人',
				address: '地址',
				postCode: '邮编',
				fax: '电话',
				taxID: '税号',
				receiverName: '联系人',
			},
			modal_add_bank: {
				titleEdit: '編輯銀行',
				title: '增加新银行',
				text: '请联系红太阳的业务助理并且告知你想要录入的银行全部信息',
			},
			confirm_modal: {
				warning:
					'警告！你可以用部分填写的文件指令来转换客户，以防你继续改变将会丢失。你想要继续吗？',
			},
			modal_no_data: {
				title: 'Bank',
				text: "No banks were found for your registration. Please contact Seara's Customer Experience team.",
			},
		},
		templateData: {
			inputs: {
				default: '标准',
				perOrder: '模板',
				template_title: '模板名称',
			},
			items: {
				invoice: '发票',
				packing: '装箱单',
				billOfLanding: '提单',
				healthCertificate: '卫生证',
				certificateOfOrigin: '原产地证',
				consignee: '收货人',
				notify: '被通知人',
				buyer: '买家',
				adress: '地址',
				observations: '额外信息',
				secondNotify: '第二被通知人',
				freigtClause: '运费条款',
				telexRelease: '电放',
				responsable: 'Responsible',
				countriesOfTransit: '转运至',
				importer: '进口人',
				behalf: '代表',
				both: '都',
			},
			actions: {
				edit: '编辑',
			},
			requests_messages: {
				template_status_change: '模板更新成功',
				template_error: '至少为提单标记一个选择',
				template_send: '文件指令成功发送',
			},
		},
	},
};
