<template>
	<div id="contractTable">
		<div class="table-container" v-if="contractTable.length != 0 && !isLoadingContractTable">
			<table class="table">
				<thead class="table__header">
					<template v-for="(row, index) in contractTable">
						<tr :key="index" v-if="index == 0">
							<template v-for="(item, key) in userPreferences.detail">
								<th :key="key" v-if="item.active">
									{{ $t(`modules.contract_management.table.detail.${item.name}`) }}
								</th>
							</template>
						</tr>
					</template>
				</thead>
				<tbody>
					<template v-for="row in contractTable">
						<tr :key="row.id" @click="handleContractDetail(row.id, contractId)">
							<template
								v-for="(item, key, index) in userPreferences.detail"
								:class="[index % 2 == 1 ? 'stripped' : '']"
							>
								<td :key="key" v-if="item.active">
									{{ formatString(row[item.name], item) }}
								</td>
							</template>
						</tr>
					</template>
				</tbody>
			</table>
		</div>
		<div class="loader" v-if="isLoadingContractTable">
			<SpinnerIcon />
		</div>
		<div class="no__data" v-if="contractTable.length == 0 && !isLoadingContractTable">
			{{ $t('errors.empty_data') }}
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import FilterTable from '@/components/shared/Icons/FilterTable.vue';
import getStatusTranslation from '@/utils/getStatusTranslation';
import SpinnerIcon from '@/components/shared/Icons/SpinnerIcon';
import moment from 'moment';

export default {
	name: 'Table',
	components: {
		FilterTable,
		SpinnerIcon,
	},
	props: {
		isWeb: {
			required: true,
		},
		contractId: {
			required: true,
		},
	},
	data: () => ({
		isLoading: false,
	}),
	mounted() {},
	methods: {
		formatString(param, item) {
			let value;
			param == null ? (value = '') : (value = param);

			if (
				value.toString().includes('-') &&
				new Date(value) !== 'Invalid Date' &&
				!isNaN(new Date(value)) &&
				param.split('-')[0].match(/^[0-9]+$/)
			) {
				value = moment(param).format('DD/MM/YYYY');
			}

			if (item.name == 'status') {
				value = getStatusTranslation(param);
			}

			if (item.name == 'stage') {
				value = this.getStageTranslation(param);
			}

			if (item.name == 'unitPrice' && param != '') {
				value = this.formatMoney(value);
			}

			if (item.name == 'totalKg' && param != '') {
				value = param?.toLocaleString(undefined, {
					minimumFractionDigits: 3,
					maximumFractionDigits: 3,
				});
			}

			return value;
		},
		handleContractDetail(id, contractId) {
			this.$router.push({
				path: '/main/contractDetail/',
				query: { id: id, contractId: contractId },
			});
		},
		getStageTranslation(stage) {
			return {
				PLANEJADA: this.$t('contract_step.planned'),
				CRIACAO: this.$t('contract_step.creation'),
				FATURADA: this.$t('contract_step.billed'),
				'LIBERADO COM RESTRICAO SEARA': this.$t('contract_step.released_seara_restriction'),
				FINALIZADO: this.$t('contract_step.finished'),
				EMBARCADO: this.$t('contract_step.boarded'),
				PROGRAMADA: this.$t('contract_step.programmed'),
				LIBERADO: this.$t('contract_step.released'),
				'DRAFT BL E VGM ENVIADO': this.$t('contract_step.draft_bl'),
				'DOCUMENTACAO ENTREGUE': this.$t('contract_step.documentation_delivered'),
				'GATE IN': this.$t('contract_step.gate_in'),
				'DOCUMENTACAO ENVIADA': this.$t('contract_step.documentation_sended'),
			}[stage];
		},
		formatMoney(value) {
			switch (this.userLang) {
				case 'pt-br':
					return value.toLocaleString('pt-br', {
						style: 'currency',
						currency: 'BRL',
					});
				case 'en-us':
					return value.toLocaleString('en-US', {
						style: 'currency',
						currency: 'USD',
					});
				case 'es-es':
					return value.toLocaleString('en-US', {
						style: 'currency',
						currency: 'EUR',
					});
				default:
					return value;
			}
		},
	},
	computed: {
		...mapGetters('contractManagement', [
			'contractTable',
			'isLoadingContractTable',
			'userPreferences',
		]),
		...mapGetters('user', ['userLang']),
	},
};
</script>

<style scoped lang="scss">
@import 'Table.scss';
</style>
