export default {
	placeholder: {
		search: '搜索',
	},
	header: {
		contractNumber: '合同号码',
		contractDate: '合同签发日期',
		competenceMonth: '月份盘',
		releaseDate: '合同发出日期',
		company: '公司名称',
		status: '阶段',
		totalVolume: '总量',
		emailNotification: '通知',
	},
	detail: {
		actualDateOfBoarding: '实际装船日期',
		awbCourier: 'AWB 运单号',
		balance: '实际装船量与订单量差额',
		billLanding: '提单',
		billLandingDate: '提单签发日期',
		blDate: '提单日期',
		blDateId: '提单日期编号',
		bookingNumber: '订舱号',
		boardingType: '装船类型',
		borderCrossingDate: '过境日期',
		borderPost: '边境站',
		borderPostsCode: '边境站代码',
		branch: '公司支部',
		brand: '品牌名称',
		brandCode: '品牌代码',
		business: '业务',
		closedContractSequence: '关闭合同序列',
		cneCode: 'CNE 代码',
		container: '柜号',
		contractSequence: '合同序列号',
		country: '国家',
		courierDate: '快递日期',
		courierCompany: '快递公司',
		csiIssuanceDate: '卡車放行日期（陸路運輸）',
		destination: '目的地',
		destinationPort: '目的港',
		destinationPort4: '备用目的港',
		dsInternationalOfc: '国际办公室',
		dueSituationCode: '到期状态代码',
		endShipDate: '截止装运日期',
		etaPodDate: '预计到港日期',
		etdPolDate: '预计离港日期',
		etdRealDate: '实际离港日期',
		etsRfsDate: '预计发货日期',
		etsRfsId: '预计发货编号',
		expectedEtdDate: '预计启运日期',
		expectedEtbDate: '预计到港日期',
		expectedEtsDate: '预计发货日期',
		gateInDate: '入门日期',
		idBlock: '区块编号',
		idStatus: '状态编号',
		incoterm: '国际贸易条款',
		initials: '产品代码',
		invoiceNumber: '发票编号',
		invoicedDate: '发票签发日期',
		invoiceIssuanceDate: '發票開立日期（陸路運輸）',
		loadNumber: '货物编码',
		loadPlace: '装运地点',
		loadCode: '装运代码',
		loadingDate: '装货日期（陸路運輸）',
		localization: '定位',
		market: '市场',
		groupingDescription: '分组描述',
		maxGroupingQuantity: '最大分组数量',
		minGroupingQuantity: '最小分组数量',
		netWeight: '净重',
		origin: '原产地',
		paymentDeadline: '付款截止日期',
		paymentDeadlineCode: '付款截止代码',
		paymentMethod: '付款方式',
		paymentMode: '付款模式',
		permissionImt: 'IMT 许可',
		permissionImtDate: 'IMT 许可日期',
		planningCode: '计划代码',
		plannerLoadingDate: '计划装货日期',
		pod: '目的港',
		pol: '装货港',
		priceType: '价格类型',
		productionMonth: '生产月份',
		productionDate: '生产日期',
		productName: '产品名称',
		provider: '供应商',
		purchaseOrder: '采购订单',
		purchaseOrderClient: '客户采购订单',
		realAtdDate: '实际发货日期',
		realAtbDate: '实际到港日期',
		realArrivalDate: '实际到达日期',
		regional: '地区',
		releaseSequenceDate: '发布序列日期',
		restritionCode: '限制代码',
		remarks: '备注',
		sentDraftDate: '草稿发送日期',
		scheduledDate: '计划日期',
		shippingCompany: '航运公司',
		shippingLine: '航线',
		sif: 'SIF 文件',
		sku: '库存单位',
		startShipDate: '起运日期',
		statusClosure: '状态关闭',
		statusPrePaid: '预付状态',
		status: '状态序列',
		stockLocation: '库存位置',
		totalGrossWeight: '总毛重',
		totalKg: '总千克',
		totalVolume: '总体积',
		traderName: '交易员姓名',
		unitPrice: '单价',
		vehiclePlate: '车牌号',
		vessel: '船名',
		weeksToShip: '装运周数',
		stage: '阶段',
	},
};
