export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS';

//CONTRACTS
export const CONTRACTS_FILTER_REQUEST = 'CONTRACTS_FILTER_REQUEST';
export const CONTRACTS_FILTER_REQUEST_SUCCESS = 'CONTRACTS_FILTER_REQUEST_SUCCESS';
export const CONTRACTS_FILTER_REQUEST_ERROR = 'CONTRACTS_FILTER_REQUEST_ERROR';
export const SAVE_CONTRACTS_FILTER = 'SAVE_CONTRACTS_FILTER';
export const CLEAR_CONTRACTS_FILTER = 'CLEAR_CONTRACTS_FILTER';

//PO
export const PO_FILTER_REQUEST = 'PO_FILTER_REQUEST';
export const PO_FILTER_REQUEST_SUCCESS = 'PO_FILTER_REQUEST_SUCCESS';
export const PO_FILTER_REQUEST_ERROR = 'PO_FILTER_REQUEST_ERROR';
export const SAVE_PO_FILTER = 'SAVE_PO_FILTER';
export const CLEAR_PO_FILTER = 'CLEAR_PO_FILTER';

//LOAD
export const LOAD_FILTER_REQUEST = 'LOAD_FILTER_REQUEST';
export const LOAD_FILTER_REQUEST_SUCCESS = 'LOAD_FILTER_REQUEST_SUCCESS';
export const LOAD_FILTER_REQUEST_ERROR = 'LOAD_FILTER_REQUEST_ERROR';
export const SAVE_LOAD_FILTER = 'SAVE_LOAD_FILTER';
export const CLEAR_LOAD_FILTER = 'CLEAR_LOAD_FILTER';

//INVOICE
export const LOAD_INVOICE_FILTER_REQUEST = 'LOAD_INVOICE_FILTER_REQUEST';
export const LOAD_INVOICE_FILTER_REQUEST_SUCCESS = 'LOAD_INVOICE_FILTER_REQUEST_SUCCESS';
export const LOAD_INVOICE_FILTER_REQUEST_ERROR = 'LOAD_INVOICE_FILTER_REQUEST_ERROR';
export const SAVE_LOAD_INVOICE_FILTER = 'SAVE_LOAD_INVOICE_FILTER';

//TRANSPORT
export const TRANSPORT_FILTER_REQUEST = 'TRANSPORT_FILTER_REQUEST';
export const TRANSPORT_FILTER_REQUEST_SUCCESS = 'TRANSPORT_FILTER_REQUEST_SUCCESS';
export const TRANSPORT_FILTER_REQUEST_ERROR = 'TRANSPORT_FILTER_REQUEST_ERROR';
export const SAVE_TRANSPORT_FILTER = 'SAVE_TRANSPORT_FILTER';
export const CLEAR_TRANSPORT_FILTER = 'CLEAR_TRANSPORT_FILTER';

//CONTAINER
export const CONTAINER_FILTER_REQUEST = 'CONTAINER_FILTER_REQUEST';
export const CONTAINER_FILTER_REQUEST_SUCCESS = 'CONTAINER_FILTER_REQUEST_SUCCESS';
export const CONTAINER_FILTER_REQUEST_ERROR = 'CONTAINER_FILTER_REQUEST_ERROR';
export const SAVE_CONTAINER_FILTER = 'SAVE_CONTAINER_FILTER';
export const CLEAR_CONTAINER_FILTER = 'CLEAR_CONTAINER_FILTER';

//PRODUCT
export const PRODUCT_FILTER_REQUEST = 'PRODUCT_FILTER_REQUEST';
export const PRODUCT_FILTER_REQUEST_SUCCESS = 'PRODUCT_FILTER_REQUEST_SUCCESS';
export const PRODUCT_FILTER_REQUEST_ERROR = 'PRODUCT_FILTER_REQUEST_ERROR';
export const SAVE_PRODUCT_FILTER = 'SAVE_PRODUCT_FILTER';
export const CLEAR_PRODUCT_FILTER = 'CLEAR_PRODUCT_FILTER';

//ETS
export const SAVE_ETS_FILTER = 'SAVE_ETS_FILTER';
export const CLEAR_ETS_FILTER = 'CLEAR_ETS_FILTER';

//ETA
export const SAVE_ETA_FILTER = 'SAVE_ETA_FILTER';
export const CLEAR_ETA_FILTER = 'CLEAR_ETA_FILTER';

export const SAVE_LAST_QUERY_STRING = 'SAVE_LAST_QUERY_STRING';

export const CLEAR_CACHED_FILTERS = 'CLEAR_CACHED_FILTERS';
